import { indigo } from '@mui/material/colors'
import GlobalStyles from '@mui/material/GlobalStyles'
import Grid from '@mui/material/Grid2'
import React, { ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'

import spaces from '../spaces'
import Logo from './Logo'

const globalStyles = (
  <GlobalStyles
    styles={{
      '*': {
        boxSizing: 'border-box' as const,
        margin: 0,
        padding: 0,
      },
      body: {
        '& > *:not(#root, [class*="Mui"])': {
          display: 'none',
        },
      },
      html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
      },
      'html, body, #root': {
        height: '100%',
      },
      'html,body,#root': {
        backgroundColor: '#fff',
        height: '100%',
        margin: 0,
        padding: 0,
      },
      'input::-ms-reveal': {
        display: 'none',
      },
      ...spaces,
    }}
  />
)

const useStyles = makeStyles({
  name: 'PageLayoutF',
})((theme) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  '@media screen and (max-height: 689px)': {
    space: {
      display: 'none',
    },
    trustpilotCard: {
      display: 'none',
    },
  },

  left: {
    '@media screen and (max-height: 565px)': {
      maxWidth: '100%',
      width: '100%',
    },
    '@media screen and (max-width: 576px)': {
      padding: theme.spacing(6),
    },
    '@media screen and (max-width: 1025px)': {
      maxWidth: '100%',
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    maxWidth: 464,

    padding: theme.spacing(10, 18, 6),
    width: '100%',
  },

  main: {
    flex: 1,
  },

  right: {
    '@media screen and (max-height: 565px)': {
      display: 'none',
    },
    '@media screen and (max-width: 1025px)': {
      display: 'none',
    },
    backgroundColor: indigo['50'],

    flexGrow: 1,
    height: '100%',
  },

  root: {
    borderLeft: `8px solid ${indigo.A700}`,
    display: 'flex',
    height: '100%',
  },
}))

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children?: ReactNode
  className?: string
  leftBottomChildren?: ReactNode
  rightChildren?: ReactNode
}

function PageLayout({
  children,
  className,
  leftBottomChildren,
  rightChildren,
  ...restProps
}: Props) {
  const { classes, cx } = useStyles()

  return (
    <>
      {globalStyles}

      <div
        className={cx(classes.root, className)}
        data-test-id="layoutRoot"
        {...restProps}
      >
        <div className={classes.left}>
          <Logo className="-mb2" />

          <Grid
            alignItems="center"
            className={classes.main}
            container
            direction="column"
            justifyContent="center"
            wrap="nowrap"
          >
            {children}
          </Grid>

          {leftBottomChildren}
        </div>

        <div className={classes.right}>{rightChildren}</div>
      </div>
    </>
  )
}

export default PageLayout
