import { Components, Theme } from '@mui/material'
import { cardContentClasses } from '@mui/material/CardContent'
import { iconClasses } from '@mui/material/Icon'

import palette from 'theme/palette'

const MuiCardActionArea: Components<Theme>['MuiCardActionArea'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`&:not(:first-of-type), & + .${cardContentClasses.root}`]: {
        boxShadow:
          theme.spacing(4) +
          ' 0 0 0 white inset, 0 1px 0 0 ' +
          palette.divider +
          ' inset',
        transition: theme.transitions.create('box-shadow'),
      },
      [`&:not(:first-of-type):hover, &:hover + &, &:hover + .${cardContentClasses.root}`]:
        {
          boxShadow:
            '0 0 0 0 white inset, 0 1px 0 0 ' + palette.divider + ' inset',
          transition: theme.transitions.create('box-shadow'),
        },
      [`& > .${cardContentClasses.root}`]: {
        flex: 1,
      },
      [`& > .${iconClasses.root}`]: {
        fontSize: 20,
        marginRight: theme.spacing(4),
      },
      alignItems: 'center',
      display: 'flex',
    }),
  },
}

export default MuiCardActionArea
