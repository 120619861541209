import { Components, Theme } from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'
import { iconClasses } from '@mui/material/Icon'
import { iconButtonClasses } from '@mui/material/IconButton'

import palette from 'theme/palette'

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  styleOverrides: {
    root: {
      '&.-contained': {
        '&:hover': {
          backgroundColor: grey['300'],
        },
        [`&.${iconButtonClasses.disabled}`]: {
          backgroundColor: grey['200'],
          color: grey['400'],
        },
        backgroundColor: grey['200'],
        color: blueGrey['500'],
      },
      '&.-delete:hover': {
        color: palette.error.main,
      },
      '&:hover': {
        backgroundColor: grey['300'],
      },
      [`&.${iconButtonClasses.disabled}`]: {
        color: grey['400'],
      },
      [`& .${iconClasses.root}`]: {
        fontSize: 16,
      },
      backgroundImage: 'none',
      backgroundPosition: '0% 0%',
      backgroundRepeat: 'repeat',
      borderRadius: 4,
      color: blueGrey['500'],
      height: 'auto',
      padding: 4,
      textTransform: 'none',
      whiteSpace: 'normal',
    },
    sizeSmall: {
      [`& .${iconClasses.root}`]: {
        fontSize: 12,
      },
      borderRadius: 4,
      fontSize: 12,
      padding: 3,
    },
  },
}

export default MuiIconButton
