import { Components, Theme } from '@mui/material'
import { checkboxClasses } from '@mui/material/Checkbox'
import { grey, indigo } from '@mui/material/colors'
import { formControlLabelClasses } from '@mui/material/FormControlLabel'
import { radioClasses } from '@mui/material/Radio'
import { switchClasses } from '@mui/material/Switch'
import { typographyClasses } from '@mui/material/Typography'

import palette from 'theme/palette'

const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    label: ({ theme }) => ({
      [`.${radioClasses.root} + &, .${checkboxClasses.root} + &`]: {
        marginLeft: theme.spacing(1),
      },
      [`.${switchClasses.root} + &`]: {
        marginLeft: theme.spacing(2),
      },
      fontWeight: 600,
    }),
    root: ({ theme }) => ({
      '&.RadioButtonCard-root': {
        '& .RadioButtonCard-startAdornment': {
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(4),
        },
        '&.RadioButtonCard-withStartAdornment': {
          '& .MuiFormControlLabel-label': {
            display: 'inline-flex',
          },
          '& .MuiRadio-root': {
            marginTop: theme.spacing(2),
          },
        },
        [`&.${formControlLabelClasses.disabled}`]: {
          '& .RadioButtonCard-startAdornment': {
            opacity: 0.5,
          },
          [`& .${formControlLabelClasses.label}:before`]: {
            borderColor: grey['300'],
          },
        },
        [`& .${formControlLabelClasses.label}`]: {
          '&:before': {
            border: '1px solid ' + palette.border.dark,
            borderRadius: 4,
            boxSizing: 'border-box',
            content: '""',
            display: 'block',
            height: '100%',
            left: 0,
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            width: '100%',
          },

          [`& .${typographyClasses.root}:nth-of-type(2)`]: {
            marginTop: theme.spacing(1),
          },
        },
        [`& .${radioClasses.root}`]: {
          [`&.${radioClasses.checked}:not(.${radioClasses.disabled})`]: {
            [`& ~ .${formControlLabelClasses.label}`]: {
              '&:before': {
                borderColor: palette.primary.main,
              },
              [`& .${typographyClasses.root}:first-of-type, & .RadioButtonCard-startAdornment + div h5.MuiTypography-root`]:
                {
                  color: palette.primary.main,
                },
            },
            color: palette.primary.main,
          },
          color: grey['400'],
          marginLeft: 0,
          marginRight: 4,

          padding: 0,
        },

        [`&:not(.${formControlLabelClasses.disabled})`]: {
          '&:hover': {
            [`& .${formControlLabelClasses.label}:before`]: {
              borderColor: palette.primary.main,
              transition: theme.transitions.create('border-color'),
            },
            [`& .${radioClasses.root}`]: {
              [`&.${radioClasses.checked}`]: {
                [`& ~ .${formControlLabelClasses.label}`]: {
                  '&:before': {
                    borderColor: indigo['700'],
                    transition: theme.transitions.create('border-color'),
                  },
                  [`& .${typographyClasses.root}:first-of-type, & .RadioButtonCard-startAdornment + div h5.MuiTypography-root`]:
                    {
                      color: palette.text.primary,
                      transition: theme.transitions.create('color'),
                    },
                },
                color: indigo['700'],
                transition: theme.transitions.create('color'),
              },
              color: palette.primary.main,
              transition: theme.transitions.create('color'),
            },
          },
          [`& .${formControlLabelClasses.label}, & .RadioButtonCard-startAdornment + div h5.MuiTypography-root`]:
            {
              [`& .${typographyClasses.root}:first-of-type`]: {
                color: palette.text.primary,
              },
              [`& .${typographyClasses.root}:nth-of-type(2), & .RadioButtonCard-startAdornment + div p.MuiTypography-root`]:
                {
                  color: palette.text.secondary,
                },
            },
        },

        alignItems: 'flex-start',
        borderRadius: theme.spacing(1),

        marginRight: 0,

        padding: theme.spacing(4),
        position: 'relative',
      },
      [`& > .${radioClasses.root}, & > .${checkboxClasses.root}`]: {
        marginLeft: -6,
      },
      [`& > .${switchClasses.root}`]: {
        marginLeft: -8,
      },
      marginLeft: 0,
      marginRight: 13,
    }),
  },
} as const

export default MuiFormControlLabel
