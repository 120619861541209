import { ApolloClient } from '@apollo/client'
import { debounce } from 'lodash-es'
import { MessageDescriptor } from 'react-intl'

import informAboutMissing from './informAboutMissing'
import { ValidMessageDescriptor } from './LanguageProvider'
import { QueryLocalesMessages } from './queries.graphql'
import replaceInterpolationSymbols from './replaceInterpolationSymbols'

type MessageDescriptorWithKey = ValidMessageDescriptor & { key: string }

let messagesToBeTranslated: Map<string, MessageDescriptorWithKey> = new Map()
const localMessageCache = new Map()

function isValidMessageDescriptor(
  message: MessageDescriptor
): message is ValidMessageDescriptor {
  if (
    !message.id ||
    !message.defaultMessage ||
    typeof message.defaultMessage !== 'string'
  ) {
    return false
  }

  return true
}

function translate(
  apolloClient: ApolloClient<unknown>,
  onNewMessages: (messages: Record<string, string>) => void
) {
  return <T extends Record<string, MessageDescriptor>>(e: CustomEvent<T>) => {
    Object.entries(e.detail).forEach(([key, messageDescriptor]) => {
      if (isValidMessageDescriptor(messageDescriptor)) {
        const defaultMessage = replaceInterpolationSymbols(
          messageDescriptor.defaultMessage
        )

        messagesToBeTranslated.set(messageDescriptor.id, {
          defaultMessage,
          id: messageDescriptor.id,
          key,
        })
      }
    })

    void fetchTranslations(apolloClient, onNewMessages)
  }
}

const fetchTranslations = debounce(async function (
  apolloClient: ApolloClient<unknown>,
  onNewMessages: (messages: Record<string, string>) => void
) {
  const messagesForThisQuery = messagesToBeTranslated
  const messageKeys = Array.from(messagesForThisQuery.values()).map(
    ({ defaultMessage, id }) => ({
      defaultMessage,
      id,
    })
  )

  messagesToBeTranslated = new Map()

  // Check which translations we already have in cache
  const localKeys = new Set(localMessageCache.keys())

  if (messageKeys.some((message) => !localKeys.has(message.id))) {
    const remoteKeys = new Set(
      messageKeys.filter((message) => !localKeys.has(message.id))
    )

    // remoteResult return all translated messages due to typePolicy, not only the new ones
    const remoteResult = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: QueryLocalesMessages,
      variables: {
        keys: Array.from(remoteKeys).map((message) => message.id),
        locale: window.locale_name,
      },
    })

    if (import.meta.env.DEV) {
      informAboutMissing(remoteResult.data.localesMessages)
    }

    // filter those messages that were fetched from remote this time,
    // as we have original keys only to those messages
    const translatedMessages = remoteResult.data.localesMessages.reduce<
      Record<string, string>
    >((allMessages, translatedMessage) => {
      const untranslatedMessage = messagesForThisQuery.get(translatedMessage.id)

      if (
        untranslatedMessage &&
        Array.from(remoteKeys).some(
          (message) => message.id === untranslatedMessage.id
        )
      ) {
        return {
          ...allMessages,
          [untranslatedMessage.id]: replaceInterpolationSymbols(
            translatedMessage.translation || untranslatedMessage.defaultMessage,
            true
          ),
        }
      }

      return allMessages
    }, {})

    if (Object.keys(translatedMessages).length) {
      Object.entries(translatedMessages).forEach(([key, message]) => {
        localMessageCache.set(key, message)
      })
      onNewMessages(translatedMessages)
    }
  }
}, 0)

export default translate
