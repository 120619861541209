import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { InputBaseProps } from '@mui/material/InputBase'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import cn from 'classnames'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

import HideIcon from 'components/Icons/Hide'
import ShowIcon from 'components/Icons/Show'

import m from './messages'
import PasswordStatus from './PasswordStatus'

type PasswordFieldProps = TextFieldProps & {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  inputProps?: InputBaseProps['inputProps']
  showStatus?: boolean
  strongPassword?: boolean
  value?: string
}

const validateOneLowercaseCharacter = (value: PasswordFieldProps['value']) =>
  Yup.string()
    .required()
    .matches(/(?=.*[a-z])/)
    .isValidSync(value)

const validateOneUppercaseCharacter = (value: PasswordFieldProps['value']) =>
  Yup.string()
    .required()
    .matches(/(?=.*[A-Z])/)
    .isValidSync(value)

const validateOneNumber = (value: PasswordFieldProps['value']) =>
  Yup.string()
    .required()
    .matches(/(?=.*[0-9])/)
    .isValidSync(value)

const validateOneSpecialCharacter = (value: PasswordFieldProps['value']) =>
  Yup.string()
    .required()
    .matches(/(?=.*[!@#$%^&*(),.?":{}|<>])/)
    .isValidSync(value)

const validateAtLeastNCharactersLong = (
  value: PasswordFieldProps['value'],
  n: number
) => Yup.string().required().min(n).isValidSync(value)

export const validatePassword = (
  value: PasswordFieldProps['value'],
  strongPassword: PasswordFieldProps['strongPassword']
) => {
  const validations = [
    validateOneLowercaseCharacter(value),
    validateOneNumber(value),
    validateAtLeastNCharactersLong(value, strongPassword ? 12 : 8),
  ]

  if (strongPassword) {
    validations.push(
      validateOneUppercaseCharacter(value),
      validateOneSpecialCharacter(value)
    )
  }

  return validations.every((validation) => validation)
}

function PasswordField({
  children,
  className,
  disabled,
  inputProps,
  showStatus = true,
  strongPassword = true,
  value,
  ...restProps
}: PasswordFieldProps) {
  const { formatMessage: f } = useIntl()
  const [hidePassword, setHidePassword] = useState(true)
  const testId = inputProps?.['data-test-id'] || 'password'

  return (
    <>
      <TextField
        {...restProps}
        className={cn(showStatus && '-mb3', className)}
        disabled={disabled}
        inputProps={{
          autoComplete: 'off',
          ...inputProps,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-test-id={`${testId}.toggleVisibility`}
                disabled={disabled}
                onClick={() => setHidePassword(!hidePassword)}
                size="large"
              >
                {hidePassword ? <ShowIcon /> : <HideIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        type={hidePassword ? 'password' : 'text'}
        value={value}
      />

      {children}

      {showStatus && (
        <>
          <PasswordStatus
            data-test-id={`${testId}.status.oneLowercaseCharacter`}
            success={validateOneLowercaseCharacter(value)}
            text={f(m.oneLowercaseCharacter)}
          />

          {strongPassword && (
            <PasswordStatus
              data-test-id={`${testId}.status.oneUppercaseCharacter`}
              success={validateOneUppercaseCharacter(value)}
              text={f(m.oneUppercaseCharacter)}
            />
          )}

          <PasswordStatus
            data-test-id={`${testId}.status.oneNumber`}
            success={validateOneNumber(value)}
            text={f(m.oneNumber)}
          />

          {strongPassword && (
            <PasswordStatus
              data-test-id={`${testId}.status.oneSpecialCharacter`}
              success={validateOneSpecialCharacter(value)}
              text={f(m.oneSpecialCharacter)}
            />
          )}

          <PasswordStatus
            data-test-id={`${testId}.status.atLeast12CharactersLong`}
            success={validateAtLeastNCharactersLong(
              value,
              strongPassword ? 12 : 8
            )}
            text={f(m.atLeastNCharactersLong, {
              n: strongPassword ? 12 : 8,
            })}
          />
        </>
      )}
    </>
  )
}

export default PasswordField
