//# loadable

import { blueGrey, green, indigo, orange, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import {
  MaterialDesignContent,
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarProviderProps,
  useSnackbar,
} from 'notistack'
import React, { CSSProperties, useEffect } from 'react'
import theme from 'theme'

import CloseIcon from 'components/Icons/DialogClose'
import ErrorIcon from 'components/Icons/Error'
import InfoIcon from 'components/Icons/Info'
import SuccessIcon from 'components/Icons/Success'
import WarningIcon from 'components/Icons/Warning'
import palette from 'theme/palette'
import { fontFamily } from 'theme/typography'

const variants = ['default', 'error', 'success', 'warning', 'info'] as const

export type ToastType = {
  error: CreateToast
  message: CreateToast
  showDOMToasts: (query: string) => void
  success: CreateToast
  warning: CreateToast
}

type CreateToast = (text: string, options: Options) => void

type Options = OptionsObject & {
  'data-testid'?: 'notification'
  'data-variant'?: Variant
  textAsHtml?: boolean
  variant?: Variant
}

type Variant = (typeof variants)[number]

let instance: ProviderContext | null
const queue: Array<[string, Options]> = []

// HACK: Proxy component to expose notistack API for legacy toasts and backward compatibility
export function SnackbarGlobalProxy() {
  const snackbar = useSnackbar()

  useEffect(() => {
    mount(snackbar)

    if (window.flash?.error) {
      error(window.flash.error, { textAsHtml: true })
    }

    if (window.flash?.success) {
      success(window.flash.success, { textAsHtml: true })
    }

    return unmount
  })

  return null
}

function createToast(...args: [string, Options]) {
  if (instance) {
    showSnackbar(...args)
  } else {
    queue.push(args)
  }
}

function error(text: string, options = {}) {
  return createToast(text, {
    variant: 'error',
    ...options,
  })
}

function message(text: string, options = {}) {
  return createToast(text, {
    variant: 'info',
    ...options,
  })
}

function mount(snackbar: ProviderContext) {
  instance = snackbar
  while (queue.length) createToast(...(queue.shift() as [string, Options]))
}

function showDOMToasts(query: string) {
  Array.from(document.querySelectorAll<HTMLElement>(query))
    .filter((element) => {
      if (
        window.flash?.error &&
        element.dataset.type === 'error' &&
        window.flash.error === element.dataset.text
      ) {
        return false
      }

      if (
        window.flash?.success &&
        element.dataset.type === 'success' &&
        window.flash.success === element.dataset.text
      ) {
        return false
      }

      return true
    })
    .forEach((element) => {
      const { text, type, ...options } = element.dataset

      if (text) {
        createToast(text, {
          textAsHtml: true,
          variant:
            type && (variants as ReadonlyArray<string>).includes(type)
              ? (type as Variant)
              : 'info',
          ...options,
        })
      }
    })
}

function showSnackbar(text: string, options: Options = {}) {
  const action = (key: SnackbarKey) => (
    <IconButton
      color="inherit"
      data-testid="close-button"
      onClick={() => instance?.closeSnackbar(key)}
      size="large"
    >
      <CloseIcon />
    </IconButton>
  )

  const { textAsHtml, ...rest } = options

  const content = textAsHtml ? (
    <div dangerouslySetInnerHTML={{ __html: text }} />
  ) : (
    text
  )

  instance?.enqueueSnackbar(content, {
    ...rest,
    action,
    SnackbarProps: {
      // @ts-expect-error custom data-* attribute
      'data-testid': 'notification',
      'data-variant': rest.variant, // Expose attribute for QA tests
      style: { top: 36 },
    },
  })
}

function success(text: string, options = {}) {
  return createToast(text, {
    variant: 'success',
    ...options,
  })
}

function unmount() {
  instance = null
}

function warning(text: string, options = {}) {
  return createToast(text, {
    variant: 'warning',
    ...options,
  })
}

const style = ({
  backgroundColor,
  fill,
}: {
  backgroundColor: CSSProperties['backgroundColor']
  fill: CSSProperties['fill']
}) => ({
  '& > div:first-child': {
    // message
    '& svg': {
      fill,
      marginRight: theme.spacing(2),
    },
    alignItems: 'flex-start',
    maxWidth: 276,
    padding: 0,

    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(70),
    },
  },

  '& > div:last-child': {
    // action
    '& button': {
      marginTop: theme.spacing(-1),
    },
    alignItems: 'flex-start',
    color: blueGrey['500'],
    marginRight: undefined,
    paddingLeft: theme.spacing(2),
  },
  alignItems: 'flex-start',
  backgroundColor,
  color: palette.text.primary,
  fontFamily,
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: 0,
  padding: theme.spacing(3, 4),
})

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-error': {
    ...style({
      backgroundColor: red['50'],
      fill: red['500'],
    }),
  },
  '&.notistack-MuiContent-info': {
    ...style({
      backgroundColor: indigo['50'],
      fill: indigo.A700,
    }),
  },
  '&.notistack-MuiContent-success': {
    ...style({
      backgroundColor: green['50'],
      fill: green.A700,
    }),
  },
  '&.notistack-MuiContent-warning': {
    ...style({
      backgroundColor: orange['50'],
      fill: orange['500'],
    }),
  },
}))

const isTestEnv = import.meta.env.MODE === 'test'

export const snackbarOptions: SnackbarProviderProps = {
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  autoHideDuration: isTestEnv ? 1000 : undefined,
  Components: {
    error: StyledMaterialDesignContent,
    info: StyledMaterialDesignContent,
    success: StyledMaterialDesignContent,
    warning: StyledMaterialDesignContent,
  },
  iconVariant: {
    error: <ErrorIcon />,
    info: <InfoIcon />,
    success: <SuccessIcon />,
    warning: <WarningIcon />,
  },
  maxSnack: 5,
  preventDuplicate: true,
  transitionDuration: isTestEnv ? 0 : undefined,
} as const

const Toast = {
  error,
  message,
  showDOMToasts,
  success,
  warning,
}

export default Toast
